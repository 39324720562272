import React, { useState } from "react";
import { Row, Container, Breadcrumb, Alert } from "react-bootstrap";
import { navigate, Link } from "gatsby";
import { useSelector } from "react-redux";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { isUserSignedIn } from "../../components/accountManagement";
import { SideBarOptions, accountMenuLinks, TopMenuOptions } from "../../components/genericMenu";
import { CrudTable } from "../../components/crudTable";

const uri = "/account/reviews/";

const UserCommentsPage = ({ location }) => {
  const userData = useSelector((state) => state.userData);
  const [bannerMessage, setBannerMessage] = useState("");

  const StatusMessage = ({ status }) => {
    if (status === "NEW") {
      return <>Pending Approval</>;
    } else if (status === "PENDING") {
      return <>Changes Pending Approval</>;
    } else if (status === "APPROVED") {
      return <>Approved</>;
    } else if (status === "REMOVED") {
      return <>Removed</>;
    } else {
      return <>Unknown</>;
    }
  };

  const CommentRow = ({ data }) => {
    var stars = <></>;
    for (var x = 0; x < data.rating; x++) {
      stars = (
        <>
          {stars}
          <div className="rating-star"></div>
        </>
      );
    }

    //console.log('data.new_comment_text='+data.new_comment_text);
    //{(data.new_comment_text === null ? data.comment_text : data.new_comment_text)}

    const clickStatusMessage = () => {
      setBannerMessage("Reference Id " + data.id);
    };
    return (
      <>
        <td>
          <Link to={"/recipes/view/" + data.comment_for_id}>{data.comment_for_name}</Link>
        </td>
        <td>{data.new_comment_text}</td>
        <td>{stars}</td>
        <td>
          <span role="button" tabIndex={0} onClick={clickStatusMessage} onKeyPress={clickStatusMessage}>
            <StatusMessage status={data.status} />
            &nbsp;
          </span>
        </td>
      </>
    );
  };

  return (
    <Layout
      pageInfo={{ pageName: "user-reviews" }}
      sideBarOptions={SideBarOptions(accountMenuLinks, uri, "My Account")}
    >
      <Seo title="User Reviews" keywords={[`reviews`]} description="User Reviews" url={uri} />
      <Container fluid>
        <div id="top-menu">
          <TopMenuOptions menuLinks={accountMenuLinks} currentLink={uri} menuTitle="My Account" />
        </div>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/">Account</Breadcrumb.Item>
            <Breadcrumb.Item active>My Reviews</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row>
          {bannerMessage === "" ? (
            <></>
          ) : (
            <Alert className="ms-2 me-2 mt-2" variant="warning">
              {bannerMessage}
            </Alert>
          )}
        </Row>
        <Row id="user-reviews-crud">
          {isUserSignedIn(userData) ? (
            <CrudTable
              crudType="user-comment"
              url="/comments"
              urlParams="comment_for=ilir-recipe"
              customFilters={[
                { name: "search_text", param_name: "search_text", default: "" },
                {
                  name: "review_status",
                  param_name: "statuses",
                  default: "ALL",
                },
                { name: "user_id", param_name: "user_id", default: "" },
              ]}
              colHeaders={["Recipe", "Comment", "Rating", "Status"]}
              rowComponent={(data) => {
                return <CommentRow data={data} />;
              }}
              actionsAllowed={["edit"]}
              editColumns={[
                {
                  name: "new_comment_text",
                  label: "Comment",
                  type: "textarea",
                  rows: "4",
                },
              ]}
              editTitle="Edit Comment"
            />
          ) : (
            <>Not logged in {navigate("/account/signin/")}</>
          )}
        </Row>
      </Container>
    </Layout>
  );
};

export default UserCommentsPage;
